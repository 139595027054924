const ProfileData = {
  resume: "/Resume_Goh_Jun_Yi.pdf",
  email: "gohjunyi00@gmail.com",
  links: {
    github: "https://github.com/Junyi00",
    linkedin: "https://sg.linkedin.com/in/goh-jun-yi",
    email: "gohjunyi00@gmail.com",
  }
};

export default ProfileData;